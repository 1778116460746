<template>
    <div>
        <v-menu
            v-if="header.form && header.table === 'files' && item[header.value] !== null"
            :key="header.title"
            open-on-hover
            offset-y
            max-width="300">
            <template v-slot:activator="{ on }">
                <v-chip
                    class="hover-cell"
                    v-on="on"
                    @click.stop.prevent="downloadFile(item, header)">
                    <table-cell-text :header="header" :item="item" :api-data="apiData" />
                    <v-icon>mdi-download</v-icon>
                </v-chip>
            </template>
        </v-menu>
        <v-menu
            v-else-if="header.form"
            :key="header.title"
            open-on-hover
            offset-y
            max-width="300">
            <template v-slot:activator="{ on }">
                <div class="hover-cell" v-on="on">
                    <table-cell-text :header="header" :item="item" :api-data="apiData" />
                </div>
            </template>
            <v-card class="edit-data-table" @click="goToTable(header.table, item[header.value])">
                <component
                    :is="header.form"
                    :value="item[header.value]"
                    :filter="filter"
                    :is-hover="true" />
            </v-card>
        </v-menu>
        <template v-else>
            <table-cell-text
                :key="header.title"
                :header="header"
                :item="item"
                :api-data="apiData" />
        </template>
    </div>
</template>

<script>
export default {
    components: {
        tableCellText: () => import("./TableCellText"),
    },
    props: {
        header: {
            type: Object,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        downloadFile: {
            type: Function,
            default: null,
        },
        goToTable: {
            type: Function,
            default: null,
        },
        apiData: {
            type: Object,
            default: null,
        },
        filter: {
            type: Object,
            default: null,
        },
    },
};
</script>
<style>
.hover-cell {
    cursor: pointer;
}
</style>
